export class FormValidator {

    errors = [];

    constructor() {
    }

    validate(element, rules) {
        for (let fieldId in rules) {
            if (!element[fieldId] || element[fieldId].length <= 0) {
                let field, label, labelText;
                field = document.getElementById(fieldId);
                if (!field) {
                    this.errors.push(`Нет поля для правила ${label.innerHTML}!`);
                }
                label = this.getLabelForId(fieldId);
                labelText = label ? label.childNodes[0].nodeValue : fieldId;
                this.errors.push(`Для поля ${labelText} не установлено значение!`);
            }
        }
        return this.errors;
    }

    getLabelForId(elementId) {
        let labels = document.getElementsByTagName('LABEL');
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].htmlFor === elementId) {
                return labels[i];
            }
        }
        return false;
    }

}
