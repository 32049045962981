import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {Spinner}                              from "../dom/Spinner";
import {Select}                               from "../dom/Select";
import {DefaultDatepicker}                    from "../dom/DefaultDatepicker";
import {StudentLinkView}                      from "./StudentLinkView";
import {CertificateSection}                   from "./CertificateSection";
import {ProgramsSelectAndAdd} from "./ProgramsSelectAndAdd";

const axios = require('axios').default;

export const ContractForm = props => {
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [element, setElement] = useState(props.element);
    const [programs, setPrograms] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [adverts, setAdverts] = useState([]);
    const formId = "contract_edit_form";
    const formIdSelector = "#" + formId;

    useEffect(() => {
    });

    useEffect(() => {
        setValidation();
        loadData();
    }, []);

    const setValidation = () => {
        $(formIdSelector).validate(
            {
                rules: {
                    "element[number]": {
                        required: true,
                    },
                    "element[course]": {
                        required: true,
                    },
                    "element[cost]": {
                        required: true,
                    },
                    "element[date_from]": {
                        required: true,
                    },
                    "element[date_to]": {
                        required: true,
                    },
                    "element[cID]": {
                        required: true,
                    },
                    "element[adver_id]": {
                        required: true,
                    },
                },
            },
        );
    };

    const handleChange = (e) => {
        const {id, value} = e.target;
        setElement({...element, [id]: value});
    };

    const handleProgramChange = (e) => {
        const value = e.target.value;
        let curProgram = getProgramById(value);
        const concatElement = {
            "course": value,
            "cost": curProgram.cost,
            "mhours": curProgram.mhours,
            "thours": curProgram.thours,
        };
        setElement({...element, ...concatElement});
    };

    const handleDiscount = (e) => {
        const discount = e.target.value;
        let newCost, curProgram = getProgramById(element.course);
        if (discount == "0") {
            newCost = curProgram.cost;
        } else {
            newCost = element.cost - (element.cost * (discount / 100));
        }
        const concatElement = {
            "cost": newCost,
        };
        setElement({...element, ...concatElement});
    };

    const setDateFrom = (date) => {
        if (date) {
            const newTs = date.getTime() / 1000;
            const strDate = date.toLocaleDateString();
            const datesProps = {
                "ts_from": newTs,
                "date_from": strDate,
            };
            setElement({...element, ...datesProps});
        }
    };

    const setDateTo = (date) => {
        if (date) {
            const newTs = Math.ceil(date.getTime() / 1000);
            const strDate = date.toLocaleDateString();
            const datesProps = {
                "ts_to": newTs,
                "date_to": strDate,
            };
            setElement({...element, ...datesProps});
        }
    };

    const getProgramById = (id) => {
        if (programs.length <= 0) {
            return false;
        }
        let curProgram = programs.filter(program => {
            return program.ID === "" + id;
        });
        return curProgram.shift();
    };

    const setContactsList = (source) => {
        return source.map(({ID, short_view}) => {
            return {"ID": ID, "name": short_view};
        });
    };

    const loadData = () => {
        // получим программы, источники информации и данные по элементу
        const programRequest = () => {
            return axios.get('/api/programs/');
        };
        const advertsRequest = () => {
            return axios.get('/api/advers/');
        };
        const contactsRequest = () => {
            return axios.get('/api/contacts/', {params: {"filtersID": `${element.sID}`}});
        };
        const lastContractNumberRequest = () => {
            return axios.get('/api/contracts/get_last_number/');
        };
        let requestPull = [programRequest(), advertsRequest(), contactsRequest(), lastContractNumberRequest()];
        axios.all(requestPull)
             .then(axios.spread((
                 programRequest,
                 advertsRequest,
                 contactsRequest,
                 lastNumber,
             ) => {
                 setPrograms(programRequest.data);
                 setContacts(setContactsList(contactsRequest.data));
                 setAdverts(advertsRequest.data);
                 if (!element.number) {
                     setElement({...element, ["number"]: +lastNumber.data + 1});
                 }
                 setLoading(false);
                 setErrors([]);
             }))
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось обработать запрос!"]);
                 setLoading(false);
             });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!$(formIdSelector).valid()) {
            return false;
        }
        setLoading(true);
        axios.put('/api/contracts/',
            {
                backUrl: props.backUrl,
                element: {
                    "ID": element.ID,
                    "sID": element.sID,
                    "cID": element.cID,
                    "number": element.number,
                    "course": element.course,
                    "cost": element.cost,
                    "offer": element.offer,
                    "mhours": element.mhours,
                    "thours": element.thours,
                    "ts_from": element.ts_from,
                    "ts_to": element.ts_to,
                    "certificate": element.certificate,
                    "certificate_number": element.certificate_number,
                    "certificate_sum": element.certificate_sum,
                    "adver_id": element.adver_id,
                },
            },
        )
             .then((response) => {
                 window.location.href = props.backUrl;
             })
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось отправить форму!"]);
                 setLoading(false);
             });
    };

    const onCertificateChange = (certificate) => {
        setElement({...element, certificate: certificate});
    };

    const onProgramChange = (program) => {
        if (!program) {
            return;
        }
        let newProgramData = {
            course: program.ID,
            program: program,
            cost: program.cost,
            mhours: program.mhours,
            thours: program.thours,
        };
        setElement({...element, ...newProgramData});
    };

    if (!element.sID) {
        return (<div className={"text-danger"}>Нельзя оформить Договор вне контекста. Нужно указать слушателя.</div>);
    }

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            <form id={formId} name={formId} onSubmit={handleSubmit} method={"POST"}>
                <input name="element[ID]" type="hidden" value={element.ID || ""}/>

                <div className="row mb-3">
                    <div className="col-12 card shadow-sm rounded-lg p-3">
                        <div className="">
                            <StudentLinkView element={element?.student || {}}/>
                        </div>
                    </div>
                </div>

                {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                <div className="row mb-3">
                    <div className={"col-12 card shadow-sm rounded-lg p-3"}>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="number">Номер договора<span
                                        className="required"> </span></label>
                                    <input id="number"
                                           className="form-control"
                                           name="element[number]"
                                           type="text"
                                           autoComplete={"off"}
                                           value={element.number || ""}
                                           required={true}
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-8">
                                <div className="form-group">
                                    <label htmlFor={"school_id"}>Программа обучения</label>

                                    <ProgramsSelectAndAdd
                                        id="course"
                                        name={"element[course]"}
                                        element={element.program || {}}
                                        elements={[]}
                                        required={true}
                                        onElementChange={onProgramChange}
                                    />
                                    {element.course ?
                                        <small className="form-text text-muted">
                                            Базовая стоимость образовательной программы - {element.cost}
                                        </small>
                                        :
                                        ""
                                    }

                                </div>
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="thours">Всего часов<span className="required"> </span></label>
                                    <input id="thours" className="form-control" name="element[thours]" type="text"
                                           autoComplete={"off"}
                                           required={true}
                                           value={element.thours || ""} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label className="display_block" htmlFor="mhours">Часов в месяц<span
                                        className="required"> </span></label>
                                    <input className="form-control" id="mhours" name="element[mhours]"
                                           onChange={handleChange}
                                           autoComplete={"off"}
                                           required={true}
                                           type="text"
                                           value={element.mhours || ""}/>
                                </div>
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <label className="display_block" htmlFor="cost">Сумма по договору
                                        <span className="required"> </span>
                                    </label>
                                    <input className="form-control" id="cost" name="element[cost]"
                                           onChange={handleChange}
                                           autoComplete={"off"}
                                           required={true}
                                           type="text"
                                           value={element.cost || 0}/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label className="display_block" htmlFor="offer">Скидка (%)</label>
                                    <input className="form-control" id="offer" name="element[offer]"
                                           onBlur={handleDiscount}
                                           onChange={handleChange}
                                           max={"100"}
                                           autoComplete={"off"}
                                           type="text"
                                           value={element.offer || ""}/>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label className="display_block" htmlFor="date_from">Дата заключения
                                        <span className="required"> </span>
                                    </label>
                                    <DefaultDatepicker
                                        id={"date_from"}
                                        name={"element[date_from]"}
                                        dateFormat={"dd.MM.yyyy"}
                                        maxDate={""}
                                        required={true}
                                        selected={element.date_from || ""}
                                        onChange={setDateFrom}/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label className="display_block" htmlFor="date_to">Дата завершения
                                        <span className="required"> </span>
                                    </label>
                                    <DefaultDatepicker
                                        id={"date_to"}
                                        name={"element[date_to]"}
                                        dateFormat={"dd.MM.yyyy"}
                                        required={true}
                                        selected={element.date_to || ""}
                                        onChange={setDateTo}
                                    />
                                </div>
                            </div>

                            <div className="col">
                                {contacts ?
                                    <div className="form-group">
                                        <Select
                                            isRequired={true}
                                            id="cID"
                                            onChange={handleChange}
                                            label={"Контакт по договору"}
                                            defaultOption={"Выбрать контакт по договору"}
                                            name={"element[cID]"}
                                            required={true}
                                            options={contacts || []}
                                            selected={element.cID || ""}/>
                                    </div>
                                    :
                                    <Fragment>
                                        <div className={"text-danger"}>оформите контакты слушателя</div>
                                    </Fragment>
                                }
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <Select
                                        id="adver_id"
                                        onChange={handleChange}
                                        label={"Источник информации"}
                                        defaultOption={"Выбрать источник"}
                                        name={"element[adver_id]"}
                                        options={adverts || []}
                                        selected={element.adver_id || ""}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {element.certificate ?
                    <div className={"row mb-3"}>
                        <div className={"col-12 card shadow-sm rounded-lg p-3"}>
                            <CertificateSection
                                studentId={element?.student?.ID}
                                certificate={element.certificate}
                                edit={false}
                                onCertificateChange={onCertificateChange}
                            />
                        </div>
                    </div>
                    :
                    ""
                }

                <div className="row">
                    <div className="col text-end">
                        <button type={"submit"} className="btn btn-primary me-3"
                                onClick={handleSubmit}>Сохранить
                        </button>
                        <button className="btn btn-secondary btn-cancel">Отменить</button>
                    </div>
                </div>

            </form>
        </Fragment>
    );
};

ContractForm.propTypes = {
    element: PropTypes.object,
    backUrl: PropTypes.string,
};
