import React, {useEffect, useState} from "react";
import PropTypes                    from "prop-types";
import {Spinner}                    from "../dom/Spinner";
import {ErrorContainer}             from "../dom/ErrorContainer";
import CreatableSelect              from 'react-select/creatable';
import {Checkbox}                   from "../dom/Checkbox";
import {FormValidator}              from "../helpers/FormValidator";

const axios = require("axios").default;

export const ProgramForm = props => {
    const [element, setElement] = useState(props.element || {});
    const [departments, setDepartments] = useState(props.departments);
    const [programsDurations, setProgramsDurations] = useState(props.programsDurations || app.constants.PROGRAMS_DURATIONS);
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
    });

    useEffect(() => {
        if (!departments) {
            loadDepartments();
        }
    }, []);

    const loadDepartments = () => {
        setLoading(true);
        axios.get(`/api/departments/`)
             .then((response) => {
                 setDepartments(response.data);
                 setLoading(false);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
                 setLoading(false);
             });
    };

    const validationRules = {
        name: {},
        shortName: {},
        link: {},
        cost: {},
        mhours: {},
        thours: {},
        long: {},
        dep: {},
    };

    const applySubmit = (event) => {
        event.applySubmit = true;
        handleSubmit(event);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);

        let validator = new FormValidator(),
            validationErrors = validator.validate(element, validationRules);
        setErrors(validationErrors);

        if (validationErrors.length > 0) {
            setLoading(false);
            return false;
        }

        axios.put('/api/programs/', {
            element: element,
            backUrl: props.backUrl,
        })
             .then((response) => {
                 //проверим, что получили ID элемента, а не ошибку обработки запроса
                 let hasError = !Number.isInteger(+response.data);
                 setSuccess(!hasError);
                 console.log(props.backUrl);
                 if (!hasError && props.backUrl && !event.applySubmit) {
                     window.location.href = props.backUrl;
                 }
                 if (hasError) {
                     setErrors(errors => [...errors, "Ошибка обработки запроса!"]);
                 }
                 setLoading(false);
             })
             .catch((errors) => {
                 setSuccess(false);
                 if (errors.data.element) {
                     setElement(errors.data.element);
                 }
                 setErrors(errors => [...errors, errors.data.errors]);
                 setLoading(false);
             });
    };

    const onDepChange = (department) => {
        let newData = {
            dep: department.code,
            department: department,
        };
        setElement({...element, ...newData});
    };

    const setNoLink = () => {
        setElement({...element, link: "не установлена"});
    };

    const onDurationChange = (duration) => {
        setElement({...element, long: duration.name});
    };

    const handleChange = (e) => {
        const {id, value} = e.target;
        setElement({...element, [id]: value});
    };

    const handleArchiveChange = (e) => {
        setElement({...element, archive: e.target.checked ? "Y" : null});
    };

    const getDurationCode = (currentDuration) => {
        return programsDurations.filter((element) => {
            if (element.name === currentDuration) {
                return element.code;
            }
        });
    };

    const selectStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 99999,
        }),
    };

    const SuccessMessage = props => (
        <div className={"alert alert-success"}>
            Программа успешно записана!
            {element.ID ? ` ID программы - ${element.ID}` : ""}
        </div>
    );

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <div className={"card shadow-sm"}>
            <div className={"card-body"}>

                {success ? <SuccessMessage/> : ""}
                {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                <div className="row mb-3">
                    <div className="form-group col">
                        <div className={"mb-3"}>
                            <Checkbox
                                label={"Архивная программа"}
                                name={"element[archive]"}
                                selected={element.archive === "Y" || false}
                                onChange={handleArchiveChange}/>
                        </div>
                        <label className="d-block" htmlFor="shortName">Краткое наименование<span
                            className="required"> </span></label>
                        <input className="form-control"
                               id="shortName"
                               name="element[shortName]"
                               onChange={handleChange}
                               required={true}
                               autoComplete={"off"}
                               type="text"
                               value={element.shortName || ""}/>
                    </div>
                    <div className="form-group col">
                        <label className="d-block" htmlFor="name">Полное наименование<span
                            className="required"> </span></label>
                        <textarea className="form-control"
                                  id={"name"}
                                  name={"element[name]"}
                                  rows={5}
                                  required={true}
                                  onChange={handleChange}
                                  autoComplete={"off"}
                                  value={element.name || ""}>
                            </textarea>
                    </div>
                </div>
                <div className={"row mb-3 align-items-end"}>
                    <div className="form-group col">
                        <label className="d-block" htmlFor="dep">Направление (отдел) УЦ, которое реализует
                            программу<span className="required"> </span></label>
                        <CreatableSelect
                            isSearchable
                            id={"dep"}
                            required={true}
                            name={"element[dep]"}
                            placeholder="Выбрать направление (отдел) для программы"
                            value={element.department}
                            options={departments || ""}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.ID}
                            noOptionsMessage={({inputValue}) => !inputValue ? "" : "Не найдено"}
                            styles={selectStyles}
                            onChange={onDepChange}
                        />
                    </div>
                    <div className="form-group col">
                        <label className="d-block float-start " htmlFor="link">Ссылка на сайте<span
                            className="required"> </span></label>

                        {!element.link ?
                            <button className="btn btn-danger btn-extra-sm float-end mb-1"
                                    onClick={setNoLink}>
                                Не установлена
                            </button>
                            :
                            ""}
                        <input className="form-control"
                               id="link"
                               name="element[link]"
                               onChange={handleChange}
                               required={true}
                               autoComplete={"off"}
                               type="text"
                               value={element.link || ""}/>
                    </div>
                    <div className="form-group col">
                        <label className="d-block" htmlFor="cost">Стоимость обучения<span
                            className="required"> </span></label>
                        <input className="form-control"
                               id="cost"
                               name="element[cost]"
                               required={true}
                               onChange={handleChange}
                               autoComplete={"off"}
                               type="number"
                               value={element.cost || ""}/>
                    </div>
                </div>

                <div className="row mb-3 align-items-end">
                    <div className="form-group col-3">
                        <label className="d-block" htmlFor="thours">Часов всего<span
                            className="required"> </span></label>
                        <input className="form-control"
                               id="thours"
                               name="element[thours]"
                               onChange={handleChange}
                               required={true}
                               autoComplete={"off"}
                               type="number"
                               value={element.thours || ""}/>
                    </div>
                    <div className="form-group col-3">
                        <label className="d-block" htmlFor="mhours">Часов в месяц<span
                            className="required"> </span></label>
                        <input className="form-control"
                               id="mhours"
                               name="element[mhours]"
                               onChange={handleChange}
                               required={true}
                               autoComplete={"off"}
                               type="number"
                               value={element.mhours || ""}/>
                    </div>
                    <div className="form-group col">
                        <label className="d-block" htmlFor="long">Продолжительность программы<span
                            className="required"> </span></label>
                        <CreatableSelect
                            isSearchable
                            onChange={onDurationChange}
                            id={"long"}
                            required={true}
                            name={"element[long]"}
                            placeholder="Выбрать продолжительность реализации программы"
                            value={getDurationCode(element.long)}
                            options={programsDurations || ""}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.code}
                            styles={selectStyles}
                        />
                    </div>
                </div>

                {!success ?
                    <div className="text-end">
                        <button className="btn btn-primary me-2"
                                onClick={handleSubmit}>
                            Сохранить
                        </button>
                        <button className="btn btn-outline-secondary me-2"
                                name="apply"
                                onClick={applySubmit}
                                value="Y">
                            Применить
                        </button>
                        <a href={props.backUrl} className="btn btn-secondary btn-cancel">отменить</a>
                    </div>
                    :
                    ""
                }

            </div>
        </div>
    );

};

ProgramForm.propTypes = {
    formAction: PropTypes.string,
    backUrl: PropTypes.string,
    departments: PropTypes.array,
    programsDurations: PropTypes.array,
    element: PropTypes.object,
};
