import {Pages}                from "./Pages";
import React                  from "react";
import ReactDOM               from "react-dom";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import {ContractForm} from "../components/ContractForm";

export class ContractsEdit extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;
        ReactDOM.render(
            <ContractForm
                inModal={this.data.inModal}
                backUrl={this.data.backUrl}
                element={this.data.element}/>,
            document.getElementById('contractForm')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
