import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import CreatableSelect                        from 'react-select/creatable';
import {Spinner}                              from "../dom/Spinner";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {ModalBootstrap}                       from "../dom/ModalBootstrap";
import {ProgramForm}                          from "./ProgramForm";

const axios = require("axios").default;

export const ProgramsSelectAndAdd = props => {
    const [element, setElement] = useState(props.element);
    const [programName, setProgramName] = useState("");
    const [elements, setElements] = useState(props.elements);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
    });

    useEffect(() => {
        if (elements.length <= 0) {
            loadElements();
        }
    }, []);

    const loadElements = () => {
        setLoading(true);
        setShowModal(false);
        axios.get(`/api/programs/`, {params: {'sortShortName': 'ASC'}}) //TODO - сортировка не работает
             .then((response) => {
                 setElements(response.data);
                 setLoading(false);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
                 setLoading(false);
             });
    };
    const handleNewElementCreate = (inputValue) => {
        setProgramName(inputValue);
        setShowModal(true);
    };

    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        return !(inputValue.trim().length === 0 ||
            selectOptions.find(option => option.name === inputValue));
    };

    const elementSelectStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 99999,
        }),
    };

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <CreatableSelect
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                id={props.id}
                name={props.name}
                placeholder="Выбрать или добавить программу"
                value={props.element || ""}
                defaultValue={props.element || ""}
                options={elements || ""}
                getOptionLabel={option => option.shortName}
                getOptionValue={option => option.ID}
                getNewOptionData={(inputValue, optionLabel) => ({
                    id: inputValue,
                    shortName: optionLabel,
                })}
                styles={elementSelectStyles}
                noOptionsMessage={({inputValue}) => !inputValue ? "" : "Не найдено"}
                onCreateOption={handleNewElementCreate}
                onChange={props.onElementChange}
                isValidNewOption={isValidNewOption}
            />

            {showModal ?
                <ModalBootstrap
                    id={"programEditModal"}
                    size={"xl"}
                    header={"Новая программа"}
                    body={<ProgramForm
                        element={{shortName: programName}}/>}
                    show={showModal}
                    onClose={loadElements}
                    showHeader={true}
                    showFooter={false}
                />
                :
                ""}
        </Fragment>
    );

};

ProgramsSelectAndAdd.propTypes = {
    element: PropTypes.object,
    elements: PropTypes.array,
    onElementChange: PropTypes.func,
};



