import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";

export const ModalBootstrap = props => {

    const [showModal, setShow] = useState(props.show);

    useEffect(() => {
        let $modal = $(`#${props.id}`);
        if (props.show || showModal) {
            $modal.modal('show');
        } else {
            $modal.modal('hide');
        }
        $modal.on('hidden.bs.modal', function (event) {
            if (typeof props.onClose === "function") {
                props.onClose();
            }
        });
    });

    return (
        <div className={`modal fade ${props.show ? "show" : ""}`}
             id={props.id}
             style={{display: `${props.show ? "block" : "none"}`}}
             tabIndex="-1"
             role="dialog"
             aria-labelledby={props.id + "Label"}
             aria-hidden="true">
            <div className={`modal-dialog modal-${props.size} modal-dialog-centered modal-dialog-scrollable`}>
                <div className="modal-content">
                    {props.showHeader ?
                        <div className="modal-header">
                            <h4 className="modal-title float-start " id={props.id + "Label"}>{props.header}</h4>
                            <button type="button"
                                    className="btn close float-end"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        :
                        ""
                    }
                    <div className="modal-body">{props.body}</div>

                    {props.showFooter ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={props.onSave}>Сохранить</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => setShow(false)}>Закрыть
                            </button>
                        </div>
                        :
                        ""
                    }

                </div>
            </div>
        </div>
    );

};

ModalBootstrap.propTypes = {
    id: PropTypes.string,
    size: PropTypes.string,
    header: PropTypes.string,
    show: PropTypes.bool,
    showFooter: PropTypes.bool,
    showHeader: PropTypes.bool,
    onClose: PropTypes.func,
};
